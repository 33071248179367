// Menu graphql query gives a flat list of menu items.

import { WP_ORIGIN } from "../constants/appContentConfig.constants";

// This will convert the flat list to a hierarchical tree compose of a tree like structure, grouping menu items (parent) with its sub menu item (children), and sub menu item's further sub menu item (grandchildren), etc.
export const flatListToHierarchical = (
  data = [] as unknown[],
  { idKey = 'key', parentKey = 'parentId', childrenKey = 'children' } = {}
) => {
  const tree = [];
  const childrenOf = {};
  data.forEach((item) => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];
    parentId
      ? (
        childrenOf[parentId] = childrenOf[parentId] || []
      ).push(newItem)
      : tree.push(newItem);
  });
  return tree;
};

// Converts WP's srcSet URL from relative to absolute via WP_ORIGIN.
export const parseSrcSet = (srcSet: string): string => {
  return srcSet.split(', ').map(s => {
    const [url, size] = s.split(' ');
    return [`${WP_ORIGIN}${url}`, size].join(' ');
  }).join(', ');
}