import './MenuTextLinks.scss';

import React from 'react';

import { WpMenu } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import BaseLink from '../BaseLink/BaseLink';

type MenuTextLinksProps = {
  className?: string,
  menu: WpMenu[],
  handleLinkClick?: () => void;
}

const MenuTextLinks: React.FC<MenuTextLinksProps> = props => {
  const p = props;
  return p.menu && p.menu.length > 0 ? <nav className={joinClassNames("MenuTextLinks", p.className)}>
    <ul id="menu-footer-text-links-menu" className="MenuTextLinksList">
      {p.menu.map(menuItem => (
        <li key={menuItem.id} className={joinClassNames(`MenuTextLinksListItem`, menuItem.cssClasses.join(" "))}>
          <BaseLink to={menuItem.url} onClick={p.handleLinkClick}>{menuItem.label}</BaseLink>
        </li>
      ))}
    </ul>
  </nav> : null
}

export default MenuTextLinks;