import './NavBar.scss';

import { globalHistory } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { action } from 'mobx';
import React, { useEffect } from 'react';

import { useControllers } from '../../controllers/app.controller';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { GeneralSettings, Menu } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import { setCSSCustomProperty } from '../../utils/css.utils';
import { debounce } from '../../utils/debounce.utils';
import { flatListToHierarchical } from '../../utils/graphql.utils';
import { useStore } from '../../utils/mobx.utils';
import { usePageContext } from '../PageTemplate/PageTemplate';
import WishlistButton from '../WishlistButton/WishlistButton';
import APP_CONTENT_CONFIG from '../../constants/appContentConfig.constants';
import BaseIcon from '../BaseIcon/BaseIcon';
import MenuTextLinks from '../MenuTextLinks/MenuTextLinks';
import MenuDiscover from '../MenuDiscover/MenuDiscover';
import ContactDetails from '../ContactDetails/ContactDetails';
import BaseLink from '../BaseLink/BaseLink';
import { Observer } from 'mobx-react-lite';
import SocialMediaSites from '../SocialMediaSites/SocialMediaSites';

type NavBarProps = {}

const NavBar: React.FC<NavBarProps> = props => {
  const { logo, discoverMenu: discoverMenuConfig, textLinksMenu: textLinksMenuConfig, contactDetails, BottomBackground, socialMedia } = APP_CONTENT_CONFIG.nav;

  const queryData = useStaticQuery(graphql`
    {
      ...SiteInfoAndSettingsFragment
      ...WpMenu_SitePrimaryFragment
      ...WpMenu_SiteDiscoverFragment
      ...WpMenu_SiteTextLinksFragment
    }
  `);
  const { siteInfo, sitePrimaryMenu, siteDiscoverMenu, siteTextLinksMenu } = queryData;

  const generalSettings: GeneralSettings = siteInfo.generalSettings;
  const primaryMenu: Menu[] = flatListToHierarchical(sitePrimaryMenu.menuItems.nodes, { idKey: "id" });
  const discoverMenu: Menu[] = discoverMenuConfig.enabled ? flatListToHierarchical(siteDiscoverMenu.menuItems.nodes, { idKey: "id" }) : [];
  const textLinksMenu: Menu[] = textLinksMenuConfig.enabled ? flatListToHierarchical(siteTextLinksMenu.menuItems.nodes, { idKey: "id" }) : [];

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        document.documentElement.classList.remove('mobile-menu-open');
      }
    })
  })

  const { UI, WISHLIST } = useControllers();

  const s = useStore(() => ({
    get navMenuState() { return UI.menuState },
    init() {
      return new Promise((resolve, reject) => {
        [
          s.initWatchers,
        ].forEach(fn => {
          try {
            fn.bind(this)();
          } catch (e) {
            console.error(e);
          }
        })
        resolve(true);
      });
    },
    initWatchers() {
      window.addEventListener('load', s.checkNavHeight);
      window.addEventListener('resize', s.checkNavHeight);
      window.addEventListener('scroll', debounce(scrollHandler, { timeout: 50 }));
    },
    checkNavHeight() {
      const nav = document.getElementById('site-nav');
      const navHeight = nav && nav.clientHeight || 128;
      setCSSCustomProperty('--nav-height', navHeight + 'px');
    },
    handleLinkClick: action(() => {
      UI.closeMenu();
      WISHLIST.closeWishlistScreen();
    }),
  }))

  const scrollHandler = () => {
    const scrollTop = window.scrollY;
    const bodyClass = document.body.classList;
    if (scrollTop > 50) {
      if (bodyClass.contains('scrolled')) return;
      bodyClass.add('scrolled');
    } else {
      if (!bodyClass.contains('scrolled')) return;
      bodyClass.remove('scrolled');
    }
  };

  useOnMount(() => {
    s.init();
  })

  const page = usePageContext();

  return <Observer children={() => (
    <>
      <nav className="SiteNav site-nav" id="site-nav">
        <div className="SiteNavContainer container">
          <div className="site-nav__logo">
            <BaseLink to='/' title={generalSettings.title} onClick={s.handleLinkClick}>
              {logo.enabled && logo.Content}
              <span>{generalSettings.title}</span>
            </BaseLink>
          </div>
          {!UI.onlySmallWidthPhones && <div className="site-nav__button-wrapper --wishlist">
            {socialMedia.enabled && <SocialMediaSites className="SiteNav__SocialMediaSites">{socialMedia.Value}</SocialMediaSites>}
            <WishlistButton asNavButton />
          </div>}
          <div className="site-nav__button-wrapper --hamburger" onClick={UI.handleToggleMenu}>
            <button className="site-nav__button menu-trigger" aria-label="Menu">
              {s.navMenuState === 'closing' || s.navMenuState === 'closed'
                ? <BaseIcon icon="menu" />
                : <BaseIcon icon="x" />
              }
              <span>Menu</span>
            </button>
          </div>
        </div>
      </nav>

      <nav className="MenuOverlay" id="site-primary-menu">
        <span className="MenuOverlayBackdrop"></span>

        <main className='NavBarMenusContainer'>
          <div className='NavBarMenusWrapper'>
            <header className="NavBarMenusHeader">
              <div>
                {primaryMenu && primaryMenu.length > 0 &&
                  <ul className='NavBarPrimaryMenuList'>
                    {primaryMenu.map(menuItem => (
                      <li key={menuItem.id} className={joinClassNames('NavBarPrimaryMenuItem', menuItem.cssClasses.join(" "), menuItem.children && menuItem.children.length > 0 ? 'menu-item-has-children' : '')}>

                      <BaseLink className="NavBarPrimaryMenuLink" to={menuItem.url} onClick={s.handleLinkClick}>{menuItem.label}</BaseLink>

                      {menuItem.children && menuItem.children.length > 0 && <ul className='NavBarPrimarySubMenuItem'>
                        {menuItem.children.map(submenuItem => (
                          <li key={submenuItem.id}>
                            <BaseLink to={submenuItem.url} onClick={s.handleLinkClick}>{submenuItem.label}</BaseLink>
                          </li>
                        ))}
                      </ul>}

                    </li>
                  ))}
                  </ul>
                }
              </div>

              {discoverMenuConfig.enabled && <MenuDiscover
                menu={discoverMenu}
                handleLinkClick={s.handleLinkClick}
              />}

              <ContactDetails contactDetails={contactDetails} />
            </header>

            <footer className="NavBarMenusFooter">
              {textLinksMenuConfig.enabled && <MenuTextLinks
                menu={textLinksMenu}
                handleLinkClick={s.handleLinkClick}
              />}
            </footer>
          </div>

          {BottomBackground && <div className='NavBarOverlayBottomBackground'>
            {BottomBackground}
          </div>}
        </main>
      </nav>
    </>
  )} />
}

export default NavBar;