import './MenuPrimaryNav.scss';

import React from 'react';
import { WpMenu } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import WishlistButton from '../WishlistButton/WishlistButton';
import BaseLink from '../BaseLink/BaseLink';

type MenuPrimaryNavProps = {
  className: string,
  menu: WpMenu[],
}

const MenuPrimaryNav: React.FC<MenuPrimaryNavProps> = props => {
  const p = props;
  return <div className={joinClassNames("MenuPrimaryNav", p.className)}>
    <div className='MenuPrimaryNavContainer'>
      <ul className="MenuPrimaryNavList">
        {p.menu.map(menuItem => (
          menuItem.label === 'Wishlist'
            ? <React.Fragment key={menuItem.id}>
              <WishlistButton className="MenuPrimaryNavLink" asListItem />
            </React.Fragment>
            : <li key={menuItem.id} className={joinClassNames(menuItem.cssClasses.join(" "))}>
              <BaseLink to={menuItem.url} className="MenuPrimaryNavLink">{menuItem.label}</BaseLink>
            </li>
        ))}
      </ul>
    </div>
  </div>
}

export default MenuPrimaryNav;