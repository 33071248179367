import './MenuDiscover.scss';

import React from 'react';

import { WpMenu } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import BaseLink from '../BaseLink/BaseLink';

type MenuDiscoverProps = {
  className?: string,
  menu: WpMenu[],
  handleLinkClick?: () => void;
}

const MenuDiscover: React.FC<MenuDiscoverProps> = props => {
  const p = props;
  return <div className={joinClassNames("MenuDiscover", p.className)}>
    <div className="MenuDiscoverContainer">
      {p.menu.map(menuItem => (
        <ul key={menuItem.id} className="MenuDiscoverList">
          <li className="MenuDiscoverNavLink" >
            <BaseLink to={menuItem.url} onClick={p.handleLinkClick}>{menuItem.label}</BaseLink>
          </li>
          <li className={joinClassNames(`MenuDiscoverMenuItem`, menuItem.cssClasses.join(" "))}>
            <ul className="sub-menu">
              {menuItem.children.map(submenuItem => (
                <li key={submenuItem.id} className={joinClassNames(`MenuDiscoverSubMenuItem`, submenuItem.cssClasses.join(" "))}>
                  <BaseLink to={submenuItem.url} onClick={p.handleLinkClick} className="MenuDiscoverNavLink">{submenuItem.label}</BaseLink>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      ))}
    </div>
  </div>
}

export default MenuDiscover;