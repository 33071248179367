import './PageSectionFeaturedImage.scss';

import { Observer } from 'mobx-react-lite';
import React from 'react';

import { Media } from '../../types/wordpress.types';
import { useProps } from '../../utils/mobx.utils';
import BaseImage from '../BaseImage/BaseImage';

type PageSectionFeaturedImageProps = {
  media: Media,
}

const PageSectionFeaturedImage: React.FC<PageSectionFeaturedImageProps> = props => {

  const p = useProps(props);

  return <Observer children={() => (
    p.media && (
      <figure className="page-section section-page-featured-image">
        {/* <span
          className="section-page-featured-image__picture__image-filler"
          data-for-attachment-id={p.media.id}
        /> */}
        <BaseImage
          className="filler section-page-featured-image__picture__image"
          media={p.media}
          imageType='gatsbyDynamic'
        />
      </figure>
    )
  )} />
}

export default PageSectionFeaturedImage;