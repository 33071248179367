import './StandardFooter.scss';

import { graphql, useStaticQuery } from 'gatsby';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import APP_CONTENT_CONFIG from '../../constants/appContentConfig.constants';
import { GeneralSettings, Menu } from '../../types/wordpress.types';
import { flatListToHierarchical } from '../../utils/graphql.utils';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import ContactDetails from '../ContactDetails/ContactDetails';
import MenuDiscover from '../MenuDiscover/MenuDiscover';
import MenuPrimaryNav from '../MenuPrimaryNav/MenuPrimaryNav';
import MenuTextLinks from '../MenuTextLinks/MenuTextLinks';
import BaseLink from '../BaseLink/BaseLink';

type StandardFooterProps = {}

const StandardFooter: React.FC<StandardFooterProps> = props => {

  const queryData = useStaticQuery(graphql`
    {
      ...SiteInfoAndSettingsFragment
      ...WpMenu_FooterPrimaryFragment
      ...WpMenu_FooterDiscoverFragment
      ...WpMenu_FooterTextLinksFragment
    }
  `);
  const { siteInfo, footerPrimaryMenu, footerDiscoverMenu, footerTextLinksMenu } = queryData;

  const generalSettings: GeneralSettings = siteInfo.generalSettings;
  const primaryMenu: Menu[] = flatListToHierarchical(footerPrimaryMenu.menuItems.nodes, { idKey: "id" });
  const discoverMenu: Menu[] = flatListToHierarchical(footerDiscoverMenu.menuItems.nodes, { idKey: "id" });
  const textLinksMenu: Menu[] = flatListToHierarchical(footerTextLinksMenu.menuItems.nodes, { idKey: "id" });

  const { copyrightText, logo, contactDetails, designerInfo, BottomBackground } = APP_CONTENT_CONFIG.footer;
  {/* <PageSection id="Footer" as="footer" className="Footer" observeVisibility> */ }
  return <footer className="page-section FooterSectionInner">
    <div className="container">

      <main className="FooterMain">
        {logo.enabled && <div className="FooterLogoWrapper">
          <BaseLink className="FooterLogo" to="/" title={generalSettings.title}>
            {logo.Content}
            <span>{generalSettings.title}</span>
          </BaseLink>
        </div>}

        <div className="FooterMainContent">
          <MenuPrimaryNav className="FooterPrimaryMenu" menu={primaryMenu} />

          <MenuDiscover
            className="FooterDiscoverMenu"
            menu={discoverMenu}
          />

          <ContactDetails className="FooterContactDetails" contactDetails={contactDetails} />
        </div>
      </main>

      <BaseSpacer size='3em' />

      <footer className="FooterFooter">
        <p className="site-footer__copyright">
          <span>{`© ${copyrightText.prefix ? copyrightText.prefix + " " : ""}${new Date().getFullYear()}`} <BaseLink to="/" title={`${generalSettings.title} Homepage`}>{generalSettings.title}</BaseLink>.</span> <span>{copyrightText.suffix}</span>
        </p>

        <MenuTextLinks
          className="FooterTextLinkMenu"
          menu={textLinksMenu}
        />

        {designerInfo.enabled && <div className="FooterDesigner">
          {designerInfo.Value}
        </div>}
      </footer>

    </div>

    {BottomBackground && <div className='FooterBottomBackground'>
      {BottomBackground}
    </div>}
  </footer>

}

export default StandardFooter;
