import './ContactDetails.scss';

import React from 'react';

import APP_CONTENT_CONFIG from '../../constants/appContentConfig.constants';
import joinClassNames from '../../utils/className.utils';
import BaseLink from '../BaseLink/BaseLink';

type ContactDetails = typeof APP_CONTENT_CONFIG.footer.contactDetails;

type ContactDetailsProps = {
  className?: string,
  linkTagClassName?: string,
  contactDetails: ContactDetails,
}

export const isNotLinkTags = (type: string) => {
  const notLinkTagTypes = ['address', 'socials'];
  return notLinkTagTypes.includes(type);
}

const ContactDetails: React.FC<ContactDetailsProps> = props => {
  const p = props;
  return p.contactDetails && p.contactDetails.length > 0 ? <div className={joinClassNames("ContactDetails", p.className)}>
    {p.contactDetails.map(((cd, outerIdx) => cd.enabled ? <React.Fragment key={outerIdx}>
        <h1 className="ContactDetailsLabel">{cd.Label}</h1>
        <div className={joinClassNames('ContactDetailsValue', isNotLinkTags(cd.type) ? "NotLinkTags" : "")}>
          {isNotLinkTags(cd.type)
            ? cd.Value
            : cd.values?.map((v, innerIdx) => <React.Fragment key={innerIdx}>
              {innerIdx > 0 && (cd.Separator ?? "")}
              <BaseLink className={p.linkTagClassName} href={v.href} title={v.title}>{v.Label}</BaseLink>
            </React.Fragment>)
          }
        </div>
      </React.Fragment> : null))}
  </div> : null
}

export default ContactDetails;