import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

import APP_CONTENT_CONFIG from '../constants/appContentConfig.constants';
import useSiteMetadata from '../hooks/useSiteMetadata.hook';
import { WpYoastSEO } from '../types/wordpress.types';
import { usePageContext } from './PageTemplate/PageTemplate';

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// import socialImage from '../content/images/axon/axondivision-social-sharing-preview.jpg';
export interface SEOProps {
  wpYoastSEO?: WpYoastSEO,
  description?: string,
  lang?: string,
  meta?: JSX.IntrinsicElements["meta"][],
  title?: string,
  image?: string,
  keywords?: string[],
  location?: Location
}

const SEO = (props: React.PropsWithChildren<SEOProps>) => {
  const { wpYoastSEO: yoast } = props;

  const { description, lang, meta, title } = usePageContext() || props;
  const defaultTitle = APP_CONTENT_CONFIG.siteMetadata.title ?? 'Gatsby Wordpress';
  const siteMeta = useSiteMetadata();
  const metaDescription = description ?? siteMeta.description;

  // TODO: add social media images

  const { SEO: SEOConfig } = APP_CONTENT_CONFIG;
  return (
    <Helmet
      htmlAttributes={{
        lang: lang ?? 'en',
      }}
      title={yoast?.title || title}
      titleTemplate={yoast?.title ? `%s` : `%s | ${defaultTitle}`}
      // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ''}
      script={[

      ]}
      link={([
        ...props.location ? [{
          rel: 'canonical',
          // href: `${siteMeta.siteUrl}${yoast?.canonical ?? props.location.pathname}`
          href: `${!yoast?.canonical.includes(siteMeta.siteUrl) ? siteMeta.siteUrl : ""}${yoast?.canonical ?? props.location.pathname}`
        }] : [],
        ...SEOConfig.fonts.google.href ? [
          {
            rel: "preconnect",
            href: "https://fonts.googleapis.com",
          },
          {
            rel: "preconnect",
            href: "https://fonts.gstatic.com",
            crossOrigin: "true",
          },
          {
            href: SEOConfig.fonts.google.href,
            rel: "preload",
            as: "style",
          },
          {
            href: SEOConfig.fonts.google.href,
            rel: "stylesheet",
          },
        ] : [],
        ...SEOConfig.fonts.typekit.id ? [
          {
            rel: "preload",
            as: "style",
            href: `https://use.typekit.net/${SEOConfig.fonts.typekit.id}.css`,
          },
          {
            rel: "stylesheet",
            href: `https://use.typekit.net/${SEOConfig.fonts.typekit.id}.css`,
          },
        ] : [],
      ])}
      meta={([
        {
          name: 'viewport',
          content: 'initial-scale=1, viewport-fit=cover, width=320',
        },
        {
          name: `description`,
          content: yoast?.metaDesc || metaDescription,
        },
        {
          name: `keywords`,
          content: yoast?.metaKeywords || props.keywords || siteMeta.keywords,
        },
        {
          property: `og:title`,
          content: yoast?.title || title,
        },
        {
          property: `og:description`,
          content: yoast?.metaDesc || metaDescription,
        },
        {
          property: `og:type`,
          content: yoast?.opengraphType || `website`,
        },
        {
          property: `og:locale`,
          content: APP_CONTENT_CONFIG.siteMetadata.lang,
        },
        {
          property: `og:url`,
          content: yoast?.opengraphUrl || siteMeta.siteUrl,
        },
        {
          property: `og:site_name`,
          content: yoast?.opengraphSiteName || defaultTitle,
        },
        {
          name: `twitter:title`,
          content: yoast?.twitterTitle || title,
        },
        {
          name: `twitter:description`,
          content: yoast?.twitterDescription || metaDescription,
        },
        ...yoast ? [
          {
            property: `article:modified_time`,
            content: yoast.opengraphModifiedTime,
          },
          {
            name: `twitter:image`,
            content: yoast.twitterImage?.mediaItemUrl,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            property: `og:image`,
            content: yoast?.opengraphImage?.mediaItemUrl,
          },
          {
            property: `og:image:width`,
            content: yoast?.opengraphImage?.localFile.childImageSharp.gatsbyImageData.width,
          },
          {
            property: `og:image:height`,
            content: yoast?.opengraphImage?.localFile.childImageSharp.gatsbyImageData.height,
          },
        ]
          : [],
        // {
        //   property: `og:image`,
        //   content: ``,
        // },
        // {
        //   name: `twitter:card`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:label1`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:data1`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:creator`,
        //   // TODO
        //   content: ``,
        // },
      ] as JSX.IntrinsicElements["meta"][]).concat(meta ?? [])}
    >
      {/* !!! Wp Yoast SEO will override the above! !!! */}
      {/* Seems to override fonts as well. No not use the below. */}
      {/* {yoast?.fullHead && ReactHtmlParser(yoast.fullHead)} */}

      {yoast?.schema?.raw && <script type="application/ld+json" className="yoast-schema-graph">
        {yoast?.schema?.raw}
      </script>}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
